<script>
import feather from "feather-icons";

export default {
  name: "Home",
  data: () => {
    return {
      theme: "",
    };
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem("theme") || "light";
  },
  updated() {
    feather.replace();
  },
  methods: {},
};
</script>

<template>
  <section
    class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
  >
    <!-- Banner left contents -->
    <div class="w-full md:w-1/3 text-left">
      <h1
        class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
      >
        Digital unterwegs
      </h1>
      <p
        class="font-general-medium mt-2 text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none text-gray-400"
      >
        Die mobile Aufschlussdatenbank
      </p>
      <div class="flex justify-evenly sm:block">
        <a
          href="https://play.google.com/store/apps/details?id=com.geology.steinmann.outcropwizard"
          class="flex justify-center items-center w-36 sm:w-48 mt-8 mb-6 sm:mb-0"
          aria-label="Android"
        >
          <!-- <i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
					></i> -->
          <img
            src="@/assets/images/playstore.png"
            class="rounded-xl w-full shadow-lg"
            alt=""
          />
        </a><a
          href="https://apps.apple.com/de/app/outcropwizard/id1495967711"
          class="flex justify-center items-center w-36 sm:w-48 mt-8 mb-6 sm:mb-0"
          aria-label="Apple"
        >
          <!-- <i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
					></i> -->
          <img
            src="@/assets/images/appstore.png"
            class="rounded-xl w-full"
            alt=""
          />
        </a>
       
      </div>
    </div>

    <!-- Banner right illustration -->
    <div class="w-full md:w-2/3 text-right float-right justify-center">
      <img
        v-if="theme === 'light'"
        src="@/assets/images/OutcropPhone13.png"
        alt="Developer"
        class="w-full h-full object-cover"
      />
      <img v-else src="@/assets/images/OutcropPhone13.png" alt="Developer" />
    </div>
  </section>
</template>

<style scoped></style>
