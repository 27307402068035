export const socialLinks = [
	{
		id: 1,
		name: 'Facebook',
		icon: 'facebook',
		url: 'https://www.facebook.com/outcropwizard',
	},
	{
		id: 2,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://instagram.com/outcropwizard',
	},
	{
		id: 3,
		name: 'Twitter',
		icon: 'twitter',
		url: 'https://twitter.com/outcropwizard',
	},
	{
		id: 4,
		name: 'YouTube',
		icon: 'youtube',
		url: 'https://www.youtube.com/@outcropwizard',
	},	{
		id: 5,
		name: 'Sketchfab',
		icon: 'box',
		url: 'https://www.sketchfab.com/outcropwizard/models',
	},
];
