<script>
import AppBanner from "@/components/shared/AppBanner";
// import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
// import Button from "../components/reusable/Button.vue";
import AboutCounter from "../components/about/AboutCounter.vue";
import AboutClients from "../components/about/AboutClients.vue";

export default {
  name: "Home",
  components: {
    AppBanner,
    AboutCounter,
    AboutClients,
    // ProjectsGrid,
    // Button,
  },
  data: () => {
    return {
      partnersHeading: "Förderer und Partner",
      partners: [
        {
          id: 1,
          title: "Universität Bonn",
          img: require("@/assets/images/brands/unibonn_color.png"),
        },
        {
          id: 2,
          title: "KTS",
          img: require("@/assets/images/brands/kts_color.png"),
        },
        {
          id: 3,
          title: "RWTH",
          img: require("@/assets/images/brands/rwth_color.png"),
        },
        {
          id: 4,
          title: "DGGV",
          img: require("@/assets/images/brands/dggv_color.png"),
        },
        {
          id: 5,
          title: "GPVE",
          img: require("@/assets/images/brands/gpve_color.png"),
        },
        {
          id: 6,
          title: "ABCJ",
          img: require("@/assets/images/brands/abcj_color.png"),
        },
      ],
      prizesHeading: "Preise",
      prizes: [
        {
          id: 1,
          title: "Initiativpreis",
          img: require("@/assets/images/awards/initiativ.jpg"),
        },
        {
          id: 2,
          title: "ETS",
          img: require("@/assets/images/awards/ets.jpg"),
        },
        {
          id: 3,
          title: "MINT",
          img: require("@/assets/images/awards/mint.jpg"),
        },
      ],
    };
  },
};
</script>

<template>
  <div class="container mx-auto">
    <!-- Banner -->
    <AppBanner class="mb-5 sm:mb-8" />

    <!-- Projects -->
    <AboutCounter />
    <!-- <div class="mt-10 sm:mt-20">
      <p
        class="font-general-semibold text-2xl sm:text-3xl text-primary-dark dark:text-primary-light"
      >
        Preise
      </p>
      <div>
        <img
          class="mt-10 sm:mt-14 rounded-lg"
          src="https://alt.outcropwizard.de/wp-content/uploads/2020/09/awards.png"
        />
      </div>
    </div> -->

    <AboutClients 
    :title="prizesHeading"
    :content="prizes"
    />
    <AboutClients 
    :title="partnersHeading"
    :content="partners"
    />

    <!-- Load more projects button -->
    <!-- <div class="mt-10 sm:mt-20 flex justify-center">
      <router-link
        to="/projects"
        class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-orange-500 hover:bg-orange-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
        aria-label="More Projects"
      >
        <Button title="Neuigkeiten" />
      </router-link>
    </div> -->
  </div>
</template>

<style scoped></style>
