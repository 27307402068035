<script>
import feather from 'feather-icons';
import AppHeader from './components/shared/AppHeader';
import AppFooter from './components/shared/AppFooter';


export default {
	components: {
		AppHeader,
		AppFooter,
	},
	data: () => {
		return {
			appTheme: localStorage.getItem('theme'),
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div :class="appTheme" class="pt-0.5">
		<!-- App header -->
		<AppHeader />

		<!-- Render active component contents with vue transition -->
		<transition name="fade" mode="out-in">
			<router-view :theme="appTheme" />
		</transition>

		<!-- Scroll to top -->
		<back-to-top
			visibleoffset="500"
			right="30px"
			bottom="20px"
			class="shadow-lg"
		>
			<i data-feather="chevron-up"></i>
		</back-to-top>

		<!-- App footer -->
		<AppFooter />
	</div>
</template>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}

:root {
	--AccentColor: #41b883;
	--ForegroundColor: #c5c5c5;
	--ForegroundColorLight: #929292;
	--BackgroundColor: #1a1a1a;
	--BackgroundAlternateColor: #242424;
}

.vue-back-to-top {
	@apply p-2 bg-orange-600 hover:bg-orange-500 text-white transition
        duration-500
        ease-in-out
        transform
        hover:-translate-y-1 hover:scale-110;
	border-radius: 50%;
	font-size: 22px;
	line-height: 22px;
}

.fade-enter-active {
	animation: coming 0.4s;
	animation-delay: 0.2s;
	opacity: 0;
}

.fade-leave-active {
	animation: going 0.4s;
}

@keyframes going {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-10px);
		opacity: 0;
	}
}

@keyframes coming {
	from {
		transform: translateX(-10px);
		opacity: 0;
	}

	to {
		transform: translateX(0px);
		opacity: 1;
	}
}

.Wrapper {
	margin-top: 40px;
}
.Section {
	/* background: var(--BackgroundAlternateColor); */
	width: 100%;
	/* max-width: 600px; */
	border-top: 1px solid var(--ForegroundColorLight);
	margin: 0;
}
.Section:last-of-type {
	border-bottom: 1px solid var(--ForegroundColorLight);
}
.Section button {
	width: 100%;
	padding: 20px 10px;
	border: none;
	background: none;
	cursor: pointer;
}

.v-collapse {
	transition: height var(--vc-auto-duration) cubic-bezier(0.33, 1, 0.68, 1);
}
.v-collapse p {
	padding: 0 20px 20px;
	margin: 0;
	/* color: var(--ForegroundColorLight); */
	/* font-size: 1rem; */
}
.Panel {
	width: 100%;
	/* font-size: 1rem;
	color: var(--ForegroundColor); */
	text-align: left;
	/* font-weight: 600; */
}
/* .Panel:hover {
	color: var(--AccentColor);
}
.Active {
	color: var(--AccentColor);
} */
</style>
