<script>
import AboutClientSingle from './AboutClientSingle.vue';

export default {
	props: ['title', 'content'],
	components: {
		AboutClientSingle,
	},
	data: () => {
		return {
			clientsHeading: 'Förderer und Partner',
			clients: [
				{
					id: 1,
					title: 'Universität Bonn',
					img: require('@/assets/images/brands/unibonn_color.png'),
				},
				{
					id: 2,
					title: 'KTS',
					img: require('@/assets/images/brands/kts_color.png'),
				},
				{
					id: 3,
					title: 'RWTH',
					img: require('@/assets/images/brands/rwth_color.png'),
				},
				{
					id: 4,
					title: 'DGGV',
					img: require('@/assets/images/brands/dggv_color.png'),
				},
				{
					id: 5,
					title: 'GPVE',
					img: require('@/assets/images/brands/gpve_color.png'),
				},
				{
					id: 6,
					title: 'ABCJ',
					img: require('@/assets/images/brands/abcj_color.png'),
				},
			],
		};
	},
};
</script>

<template>
	<div class="mt-10 sm:mt-20">
		<p
			class="font-general-semibold text-2xl sm:text-3xl text-primary-dark dark:text-primary-light"
		>
			{{ title }}
		</p>
		<div class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-10 sm:mt-14 gap-10 justify-between">
			<AboutClientSingle
				v-for="client in content"
				:key="client.id"
				:client="client"
			/>
		</div>
	</div>
</template>
